import * as React from "react";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import { breakpoints } from "../styledResponsive";

export const Container = styled.div`
    width: 100%;
    padding: 40px;
    margin: auto;
    ${breakpoints("width", ["100%", "100%", "40%", "40%"])};
`;

export const Title = styled.h3`
    margin-top: 100px;
    text-transform: uppercase;
    font-size: 28px;
    text-align: center;
    font-weight: 400;
    padding: 30px;
    margin-bottom: 32px;
`;

export const Paragraph = styled.p`
    font-size: 18px;
    text-align: left;
    font-weight: 100;
    width: 100%;
`;

export const Links = styled.a`
    text-decoration: none;
`;

const PoliticaDePrivacidade = () => (
    <Layout>
        <Container>
            <Title>Política de Privacidade - LGPD</Title>
            <Paragraph>
                Na Lex Empreendimentos, privacidade e segurança são prioridades
                e nos comprometemos com a transparência do tratamento de dados
                pessoais dos nossos usuários/clientes. Por isso, esta presente
                Política de Privacidade estabelece como é feita a coleta, uso e
                transferência de informações de clientes ou outras pessoas que
                acessam ou usam nosso site. <br />
                <br />
                Ao utilizar nossos serviços, você entende que coletaremos e
                usaremos suas informações pessoais nas formas descritas nesta
                Política, sob as normas da Lei Geral de Proteção de Dados
                Pessoais (LGPD, Lei Federal 13.709/2018), das disposições
                consumeristas da Lei Federal 8078/1990 e as demais normas do
                ordenamento jurídico brasileiro aplicáveis.
                <br />
                <br />
                Dessa forma, a Lex Empreendimentos, doravante denominada
                simplesmente como “Lex”, inscrita no CNPJ/MF sob o nº
                (89.804.512/0001-14), no papel de Controladora de Dados,
                obriga-se ao disposto na presente Política de Privacidade.
                <br />
                <br />{" "}
                <Title>
                    1. Quais dados coletamos sobre você e para qual finalidade?
                </Title>
                <br />
                <br />
                Nosso site coleta e utiliza alguns dados pessoais seus, de forma
                a viabilizar a prestação de serviços e aprimorar a experiência
                de uso.
                <br />
                <br />
                1.1. Dados pessoais fornecidos pelo titular
                <br />
                <br />
                A coleta de dados tem por finalidade atuar de forma eficaz e
                proporcionar melhorias na experiência dos usuários com os
                serviços oferecidos neste site. Em regra, o tratamento de dados
                pessoais tem por finalidade a prestação dos serviços de
                informações aos clientes, nos termos da legislação vigente. A
                maior parte desses dados é solicitada de maneira explícita por
                meio de formulários eletrônicos e será usada exclusivamente para
                atender as solicitações enviadas aos serviços prestados por
                essas ferramentas, de modo a agilizar e cumprir sua finalidade.
                <br />
                <br />
                Alguns aplicativos e o site da Terroir utilizam dados extraídos
                do Google Analytics apenas para fins estatísticos e
                aprimoramento da experiência do usuário, como subsídio para a
                melhoria da qualidade e do funcionamento de seus serviços. As
                análises estatísticas serão efetuadas para interpretar os
                padrões de utilização do site e serviços disponíveis, a fim de
                melhorar, de forma contínua, a prestação dos serviços. A
                informação estatística resultante poderá ser objeto de
                publicação, sem qualquer identificação pessoal dos usuários.
                Caso ocorram mudanças na finalidade do tratamento dos dados
                pessoais, não compatíveis com a base legal, o titular será
                informado previamente, garantido o direito de solicitar a
                eliminação dos dados, se discordar das alterações.
                <br />
                <br />
                1.2. Dados pessoais coletados automaticamente
                <br />
                <br />
                <Title>2. Como coletamos os seus dados?</Title>
                <br />
                <br />
                Nesse sentido, a coleta dos seus dados pessoais ocorre da
                seguinte forma:
                <br />
                <br />
                A coleta de dados tem por finalidade atuar de forma eficaz e
                proporcionar melhorias na experiência dos usuários com os
                serviços oferecidos neste site. Em regra, o tratamento de dados
                pessoais tem por finalidade a prestação dos serviços de
                informações aos clientes, nos termos da legislação vigente. A
                maior parte desses dados é solicitada de maneira explícita por
                meio de formulários eletrônicos e será usada exclusivamente para
                atender as solicitações enviadas aos serviços prestados por
                essas ferramentas, de modo a agilizar e cumprir sua finalidade.
                <br />
                <br />
                2.1. Consentimento
                <br />
                <br />
                É a partir do seu consentimento que tratamos os seus dados
                pessoais. O consentimento é a manifestação livre, informada e
                inequívoca pela qual você autoriza a Lex Empreendimentos a
                tratar seus dados.
                <br />
                <br />
                Assim, em consonância com a Lei Geral de Proteção de Dados
                Pessoais (LGPD), seus dados só serão coletados, tratados e
                armazenados mediante prévio e expresso consentimento.
                <br />
                <br />
                O seu consentimento será obtido de forma específica para cada
                finalidade acima descrita, evidenciando o compromisso de
                transparência e boa-fé da Lex Empreendimentos para com seus
                usuários/clientes, seguindo as regulações legislativas
                pertinentes.
                <br />
                <br />
                Ao utilizar os serviços da Lex Empreendimentos e fornecer seus
                dados pessoais, você está ciente e consentindo com as
                disposições desta Política de Privacidade, além de conhecer seus
                direitos e como exercê-los.
                <br />
                <br />
                A qualquer tempo e sem nenhum custo, você poderá revogar seu
                consentimento.
                <br />
                <br />
                É importante destacar que a revogação do consentimento para o
                tratamento dos dados pode implicar a impossibilidade da
                performance adequada de alguma funcionalidade do site que
                dependa da operação. Tais consequências serão informadas
                previamente.
                <br />
                <br />
                <Title>3. Quais são os seus direitos?</Title>
                <br />
                <br />
                A Lex Empreendimentos assegura a seus usuários/clientes seus
                direitos de titular previstos no artigo 18 da Lei Geral de
                Proteção de Dados Pessoais - LGPD. Dessa forma, você pode, de
                maneira gratuita e a qualquer tempo:
                <br />
                <br />
                Confirmar a existência de tratamento de dados, de maneira
                simplificada ou em formato claro e completo.
                <br />
                <br />
                Acessar seus dados, podendo solicitá-los em uma cópia legível
                sob forma impressa ou por meio eletrônico, seguro e idôneo.
                <br />
                <br />
                Corrigir seus dados, ao solicitar a edição, correção ou
                atualização destes.
                <br />
                <br />
                Limitar seus dados quando desnecessários, excessivos ou tratados
                em desconformidade com a legislação através da anonimização,
                bloqueio ou eliminação.
                <br />
                <br />
                Solicitar a portabilidade de seus dados, através de um relatório
                de dados cadastrais que a Lex Empreendimentos trata a seu
                respeito.
                <br />
                <br />
                Eliminar seus dados tratados a partir de seu consentimento,
                exceto nos casos previstos em lei.
                <br />
                <br />
                Revogar seu consentimento, desautorizando o tratamento de seus
                dados.
                <br />
                <br />
                Informar-se sobre a possibilidade de não fornecer seu
                consentimento e sobre as consequências da negativa.
                <br />
                <br />
                <Title>
                    4. Como você pode exercer seus direitos de titular?
                </Title>
                <br />
                <br />
                Para exercer seus direitos de titular, você deve entrar em
                contato com a Lex Empreendimentos através dos seguintes meios
                disponíveis:
                <br />
                <br />
                contato@leximoveis.com.br
                <br />
                54 3452-3455
                <br />
                <br />
                De forma a garantir a sua correta identificação como titular dos
                dados pessoais objeto da solicitação, é possível que solicitemos
                documentos ou demais comprovações que possam comprovar sua
                identidade. Nessa hipótese, você será informado previamente.
                <br />
                <br />
                <Title>
                    5. Como e por quanto tempo seus dados serão armazenados?
                </Title>
                <br />
                <br />
                Seus dados pessoais coletados pela Lex Empreendimentos serão
                utilizados e armazenados durante o tempo necessário para a
                prestação do serviço ou para que as finalidades <br />
                elencadas na presente Política de Privacidade sejam atingidas,
                considerando os direitos dos titulares dos dados e dos
                controladores.
                <br />
                <br />
                De modo geral, seus dados serão mantidos enquanto a relação
                contratual entre você e a Lex Empreendimentos perdurar. Findado
                o período de armazenamento dos dados pessoais, estes serão
                excluídos de nossas bases de dados ou anonimizados, ressalvadas
                as hipóteses legalmente previstas no artigo 16 lei geral de
                proteção de dados, a saber:
                <br />
                <br />
                I – cumprimento de obrigação legal ou regulatória pelo
                controlador;
                <br />
                II – estudo por órgão de pesquisa, garantida, sempre que
                possível, a anonimização dos dados pessoais;
                <br />
                III – transferência a terceiro, desde que respeitados os
                requisitos de tratamento de dados dispostos nesta Lei; ou
                <br />
                IV – uso exclusivo do controlador, vedado seu acesso por
                terceiro, e desde que anonimizados os dados.
                <br />
                <br />
                Isto é, informações pessoais sobre você que sejam
                imprescindíveis para o cumprimento de determinações legais,
                judiciais e administrativas e/ou para o exercício do direito de
                defesa em processos judiciais e administrativos serão mantidas,
                a despeito da exclusão dos demais dados.
                <br />
                <br />
                O armazenamento de dados coletados pela Lex Empreendimentos
                reflete o nosso compromisso com a segurança e privacidade dos
                seus dados. Empregamos medidas e soluções técnicas de proteção
                aptas a garantir a confidencialidade, integridade e
                inviolabilidade dos seus dados. Além disso, também contamos com
                medidas de segurança apropriadas aos riscos e com controle de
                acesso às informações armazenadas.
                <br />
                <br />
                <Title>6. O que fazemos para manter seus dados seguros?</Title>
                <br />
                <br />
                Para mantermos suas informações pessoais seguras, usamos
                ferramentas físicas, eletrônicas e gerenciais orientadas para a
                proteção da sua privacidade.
                <br />
                <br />
                Aplicamos essas ferramentas levando em consideração a natureza
                dos dados pessoais coletados, o contexto e a finalidade do
                tratamento e os riscos que eventuais violações gerariam para os
                direitos e liberdades do titular dos dados coletados e tratados.
                <br />
                <br />
                Entre as medidas que adotamos, destacamos as seguintes:
                <br />
                <br />
                Apenas pessoas autorizadas têm acesso a seus dados pessoais
                <br />
                O acesso a seus dados pessoais é feito somente após o
                compromisso de confidencialidade
                <br />
                Seus dados pessoais são armazenados em ambiente seguro e idôneo.
                <br />
                <br />
                A Lex Empreendimentos se compromete a adotar as melhores
                posturas para evitar incidentes de segurança. Contudo, é
                necessário destacar que nenhuma página virtual é inteiramente
                segura e livre de riscos. É possível que, apesar de todos os
                nossos protocolos de segurança, problemas de culpa
                exclusivamente de terceiros ocorram, como ataques cibernéticos
                de hackers, ou também em decorrência da negligência ou
                imprudência do próprio usuário/cliente.
                <br />
                <br />
                Em caso de incidentes de segurança que possa gerar risco ou dano
                relevante para você ou qualquer um de nossos usuários/clientes,
                comunicaremos aos afetados e a Autoridade Nacional de Proteção
                de Dados sobre o ocorrido, em consonância com as disposições da
                Lei Geral de Proteção de Dados.
                <br />
                <br />
                <Title>7. Com quem seus dados podem ser compartilhados?</Title>
                <br />
                <br />
                Tendo em vista a preservação de sua privacidade, a Lex
                Empreendimentos não compartilhará seus dados pessoais com nenhum
                terceiro não autorizado.
                <br />
                <br />
                Seus dados poderão ser compartilhados com nossos parceiros
                comerciais: (Lex Empreendimentos Imobiliários), inscrito no
                CPF/CNPJ sob o nº 89.804.512/0001-14.
                <br />
                <br />
                Estes recebem seus dados apenas na medida do necessário para a
                prestação dos serviços contratados e nossos contratos são
                orientados pelas normas de proteção de dados do ordenamento
                jurídico brasileiro.
                <br />
                <br />
                Todavia, nossos parceiros têm suas próprias Políticas de
                Privacidade, que podem divergir desta. Recomendamos a leitura
                desses documentos, que você pode acessar aqui:
                <br />
                <br />
                Política de Privacidade do nosso parceiro:.
                <br />
                <br />
                Além disso, também existem outras hipóteses em que seus dados
                poderão ser compartilhados, que são:
                <br />
                <br />
                I – Determinação legal, requerimento, requisição ou ordem
                judicial, com autoridades judiciais, administrativas ou
                governamentais competentes.
                <br />
                II – Caso de movimentações societárias, como fusão, aquisição e
                incorporação, de forma automática
                <br />
                III – Proteção dos direitos da Lex Empreendimentos em qualquer
                tipo de conflito, inclusive os de teor judicial.
                <br />
                <br />
                7.1. Transferência internacional de dados
                <br />
                <br />
                Alguns dos terceiros com quem compartilhamos seus dados podem
                ser localizados ou ou possuir instalações localizadas em países
                estrangeiros. Nessas condições, de toda forma, seus dados
                pessoais estarão sujeitos à Lei Geral de Proteção de Dados
                Pessoais e às demais legislações brasileiras de proteção de
                dados
                <br />
                <br />
                Nesse sentido, a Lex Empreendimentos se compromete a sempre
                adotar eficientes padrões de segurança cibernética e de proteção
                de dados, nos melhores esforços de garantir e cumprir as
                exigências legislativas.
                <br />
                <br />
                Ao concordar com essa Política de Privacidade, você concorda com
                esse compartilhamento, que se dará conforme as finalidades
                descritas no presente instrumento.
                <br />
                <br />
                <Title>8. Cookies ou dados de navegação</Title>
                <br />
                <br />
                A Lex Empreendimentos faz uso de Cookies, que são arquivos de
                texto enviados pela plataforma ao seu computador e que nele se
                armazenam, que contém informações relacionadas à navegação do
                site. Em suma, os Cookies são utilizados para aprimorar a
                experiência de uso.
                <br />
                <br />
                Ao acessar nosso site e consentir com o uso de Cookies, você
                manifesta conhecer e aceitar a utilização de um sistema de
                coleta de dados de navegação com o uso de Cookies em seu
                dispositivo.
                <br />
                <br />
                A Lex Empreendimentos utiliza os seguintes Cookies: (descrição
                dos tipos de Cookies utilizados pelo site).
                <br />
                <br />
                <div style={{ width: 800, marginTop: 32, marginBottom: 32 }}>
                    <table className="responsiveTable">
                        <thead>
                            <tr>
                                <td colSpan={7} style={{ fontSize: 11 }}>
                                    Cookies relacionados com atividades
                                    estritamente necessárias para o
                                    funcionamento do site e a prestação de
                                    serviços
                                </td>
                            </tr>
                            <tr>
                                <th style={{ fontSize: 11 }}>Cookie</th>
                                <th style={{ fontSize: 11 }}>Descrição</th>
                                <th style={{ fontSize: 11 }}>Categoria</th>
                                <th style={{ fontSize: 11 }}>Expira em</th>
                                <th style={{ fontSize: 11 }}>Domínio</th>
                                <th style={{ fontSize: 11 }}>Usado em</th>
                                <th style={{ fontSize: 11 }}>
                                    Quem tem acesso?
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                style={{
                                    fontSize: 11,
                                    paddingTop: 24,
                                    paddingBottom: 24,
                                }}
                            >
                                <td>policies-set</td>
                                <td>Cookie de Personalização</td>
                                <td>essencial</td>
                                <td>1 ano</td>
                                <td>terroirvinhedos.com.br</td>
                                <td>Em todo o site</td>
                                <td>Lex Empreendimentos</td>
                            </tr>
                            <tr style={{ fontSize: 11 }}>
                                <td>cookies-allowed</td>
                                <td>Cookie de Personalização</td>
                                <td>essencial</td>
                                <td>1 ano</td>
                                <td>terroirvinhedos.com.br</td>
                                <td>Em todo o site</td>
                                <td>Lex Empreendimentos</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                Você pode, a qualquer tempo e sem nenhum custo, alterar as
                permissões, bloquear ou recusar os Cookies. Todavia, a revogação
                do consentimento de determinados Cookies pode inviabilizar o
                funcionamento correto de alguns recursos da plataforma.
                <br />
                <br />
                Para gerenciar os cookies do seu navegador, basta fazê-lo
                diretamente nas configurações do navegador, na área de gestão de
                Cookies. Você pode acessar tutoriais sobre o tema diretamente
                nos links abaixo:
                <br />
                <br />
                <Links
                    href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    target="_blank"
                >
                    Se você usa o Internet Explorer.
                </Links>
                <br />
                <Links
                    href="https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador"
                    target="_blank"
                >
                    Se você usa o Firefox.
                </Links>
                <br />
                <Links
                    href="http://safari.helpmax.net/pt/privacidade-e-seguranca/como-gerenciar-cookies/"
                    target="_blank"
                >
                    Se você usa o Safari.
                </Links>
                <br />
                <Links
                    href="https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop"
                    target="_blank"
                >
                    Se você usa o Google Chrome.
                </Links>
                <br />
                <Links
                    href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=No%20Edge%2C%20selecione%20Configura%C3%A7%C3%B5es%20e%20mais%20%3E%20Configura%C3%A7%C3%B5es%20%3E%20Cookies%20e,cujos%20cookies%20voc%C3%AA%20deseja%20excluir."
                    target="_blank"
                >
                    Se você usa o Microsoft Edge.
                </Links>
                <br />
                <Links
                    href="https://www.opera.com/pt-br/features/bookmarks"
                    target="_blank"
                >
                    Se você usa o Opera.
                </Links>
                <br />
                <br />
                Você pode ter maiores informações sobre os Cookies que
                utilizamos e como eles funcionam na nossa Política de Cookies,
                disponível neste link (link para a Política de Cookies).
                <br />
                <br />
                <Title>9. Alteração desta Política de Privacidade</Title>
                <br />
                <br />
                A atual versão da Política de Privacidade foi formulada e
                atualizada pela última vez em: (data da última atualização da
                Política de Privacidade).
                <br />
                <br />
                Reservamos o direito de modificar essa Política de Privacidade a
                qualquer tempo, principalmente em função da adequação a
                eventuais alterações feitas em nosso site ou em âmbito
                legislativo. Recomendamos que você a revise com frequência.
                <br />
                <br />
                Eventuais alterações entrarão em vigor a partir de sua
                publicação em nosso site e sempre lhe notificaremos acerca das
                mudanças ocorridas.
                <br />
                <br />
                Ao utilizar nossos serviços e fornecer seus dados pessoais após
                tais modificações, você as consente.
                <br />
                <br />
                <Title>10. Responsabilidade</Title>
                <br />
                <br />
                A Lex Empreendimentos prevê a responsabilidade dos agentes que
                atuam nos processos de tratamento de dados, em conformidade com
                os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
                <br />
                <br />
                Nos comprometemos em manter esta Política de Privacidade
                atualizada, observando suas disposições e zelando por seu
                cumprimento.
                <br />
                <br />
                Além disso, também assumimos o compromisso de buscar condições
                técnicas e organizativas seguramente aptas a proteger todo o
                processo de tratamento de dados.
                <br />
                <br />
                Caso a Autoridade Nacional de Proteção de Dados exija a adoção
                de providências em relação ao tratamento de dados realizado pela
                Lex Empreendimentos, comprometemo-nos a segui-las.
                <br />
                <br />
                10.1 Isenção de responsabilidade
                <br />
                <br />
                Conforme mencionado no Tópico 6, embora adotemos elevados
                padrões de segurança a fim de evitar incidentes, não há nenhuma
                página virtual inteiramente livre de riscos. Nesse sentido, a
                Lex Empreendimentos não se responsabiliza por:
                <br />
                <br />
                I – Quaisquer consequências decorrentes da negligência,
                imprudência ou imperícia dos usuários em relação a seus dados
                individuais. Garantimos e nos responsabilizamos apenas pela
                segurança dos processos de tratamento de dados e do cumprimento
                das finalidades descritas no presente instrumento.
                <br />
                <br />
                Destacamos que a responsabilidade em relação à confidencialidade
                dos dados de acesso é do usuário.
                <br />
                <br />
                II – Ações maliciosas de terceiros, como ataques de hackers,
                exceto se comprovada conduta culposa ou deliberada da Lex
                Empreendimentos.
                <br />
                <br />
                Destacamos que em caso de incidentes de segurança que possam
                gerar risco ou dano relevante para você ou qualquer um de nossos
                usuários/clientes, comunicaremos aos afetados e a Autoridade
                Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as
                providências necessárias.
                <br />
                <br />
                III – Inveracidade das informações inseridas pelo
                usuário/cliente nos registros necessários para a utilização dos
                serviços da Lex Empreendimentos; quaisquer consequências
                decorrentes de informações falsas ou inseridas de má-fé são de
                inteiramente responsabilidade do usuário/cliente.
                <br />
                <br />
                <Title>11. Encarregado de Proteção de Dados</Title>
                <br />
                <br />
                A Lex Empreendimentos disponibiliza os seguintes meios para que
                você possa entrar em contato conosco para exercer seus direitos
                de titular: contato@leximoveis.com.br.
                <br />
                <br />
                Caso tenha dúvidas sobre esta Política de Privacidade ou sobre
                os dados pessoais que tratamos, você pode entrar em contato com
                o nosso Encarregado de Proteção de Dados Pessoais, através dos
                seguintes canais: contato@leximoveis.com.br
                <br />
                <br />
                Lex Empreendimentos
                <br />
                contato@leximoveis.com.br
            </Paragraph>
        </Container>
    </Layout>
);

export default PoliticaDePrivacidade;
